body {
  color: #f1f1f1;
}

.job {
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  padding: 20px 10px;
  align-items: center;
  min-width: 330px;
}

.job:hover {
  cursor: pointer;
  color: #666666;
}

.detail-logo {
  height: 30px;
  float: right;
}

.job-company {
  padding: 0 30px;
}

.job-dialog {
  padding: 30px 50px;
}

.welcome-description {
  width: 80%;
  margin: 20px 0;
  padding: 20px 10px;
}

.welcome-item {
  opacity: 0;
  transition: .5s ease all;
}

.flex-align-mid {
  display: flex;
  align-items: center;
}

.job-title-location {
  margin-left: 20px;
}

.logo-container {
  display: flex;
  flex-direction: column;
}

.logo-spacer {
  width: 40px;
}

.location-radio-container {
  width: 45%;
  padding: 20px;
}

.location-radio-item {
  padding: 0 10px;
}

@media only screen and (max-width: 900px) {
  .sitting-guy {
    display: none;
  }
}